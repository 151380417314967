import React from "react"
import { Flex, Box, Stack } from "@chakra-ui/react"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { ArrowDownIcon } from "@chakra-ui/icons"

const MotionHeading = motion.custom(Box)

export default ({ image, title, subTitle, ...props }) => {
  const heading = {
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.15 },
    }),
    hidden: {
      opacity: 0,
      y: 48,
    },
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        as={Img}
        fluid={image.childImageSharp.fluid}
        backgroundColor={image.colors.darkVibrant}
        alt={image.altText ? image.altText : ""}
        position="absolute"
        height="100vh"
        maxHeight={["auto", "100vw"]}
        width="100%"
        {...props}
      />
      {/**
      <Box
        sx={{
          position: "absolute",
          bottom: -1,
          zIndex: 2,
          width: "100%",
          height: "15%",
          backgroundImage:
            colorMode === "light"
              ? "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%)"
              : "linear-gradient(180deg, rgba(11, 27, 44, 0) 0%, rgba(11, 27, 44, 0.0086472) 6.67%, rgba(11, 27, 44, 0.03551) 13.33%, rgba(11, 27, 44, 0.0816599) 20%, rgba(11, 27, 44, 0.147411) 26.67%, rgba(11, 27, 44, 0.231775) 33.33%, rgba(11, 27, 44, 0.331884) 40%, rgba(11, 27, 44, 0.442691) 46.67%, rgba(11, 27, 44, 0.557309) 53.33%, rgba(11, 27, 44, 0.668116) 60%, rgba(11, 27, 44, 0.768225) 66.67%, rgba(11, 27, 44, 0.852589) 73.33%, rgba(11, 27, 44, 0.91834) 80%, rgba(11, 27, 44, 0.96449) 86.67%, rgba(11, 27, 44, 0.991353) 93.33%, #0B1B2C 100%)",
        }}
      />
       */}
      {(title || subTitle) && (
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(180deg, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.07) 15%, rgba(0,0,0,0.09) 27%, rgba(0,0,0,0.12) 36%, rgba(0,0,0,0.15) 44%, rgba(0,0,0,0.18) 51%, rgba(0,0,0,0.22) 56%, rgba(0,0,0,0.25) 60%, rgba(0,0,0,0.29) 64%, rgba(0,0,0,0.33) 68%, rgba(0,0,0,0.37) 73%, rgba(0,0,0,0.41) 78%, rgba(0,0,0,0.44) 84%, rgba(0,0,0,0.47) 91%, rgba(0,0,0,0.50) 100%)",
          }}
        >
          <Stack align="center" spacing={2} maxWidth="7xl">
            <MotionHeading
              as="h1"
              color="white"
              m={0}
              px={6}
              letterSpacing="tight"
              fontSize={["3xl", "4xl", "5xl", "6xl", "7xl"]}
              fontWeight="bolder"
              lineHeight="shorter"
              textAlign="center"
              custom={1}
              variants={heading}
              initial="hidden"
              animate="visible"
            >
              {title}
            </MotionHeading>
            {subTitle && (
              <MotionHeading
                as="h2"
                m={0}
                color="white"
                lineHeight="shorter"
                textAlign="center"
                fontSize={["xl", "2xl", "3xl", "4xl"]}
                fontWeight="normal"
                custom={2}
                variants={heading}
                initial="hidden"
                animate="visible"
              >
                {subTitle}
              </MotionHeading>
            )}
          </Stack>
          <MotionHeading
            as={Box}
            position="absolute"
            bottom={8}
            custom={3}
            variants={heading}
            initial="hidden"
            animate="visible"
          >
            <ArrowDownIcon color="whiteAlpha.500" width={10} height={10} />
          </MotionHeading>
        </Flex>
      )}
    </Box>
  )
}
