import React from "react"
import { Box, Stack, Container } from "@chakra-ui/react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Img from "gatsby-image"

const GenericPage = ({ data, pageContext }) => {
  const page = data.wpPage
  const language = pageContext.language
  const { body } = page.pageBuilder.layouts[0]

  // Helper function to modify urls
  const convertUrls = (text) => {
    return text.split("<a").join('<a target="_blank"')
  }

  let images = []
  if (page.pageBuilder.layouts[1] !== undefined) {
    images = page.pageBuilder.layouts[1].images
  }
  return (
    <Layout language={language} overlayHeader={true}>
      <SEO
        title={page.title}
        lang={language}
        alternates={pageContext.alternates}
      />
      <Stack spacing={24}>
        <Hero title={page.title} subTitle="" image={data.hero} />
        <Box>
          <Container
            maxWidth="5xl"
            fontSize={{ md: "lg", lg: "xl" }}
            dangerouslySetInnerHTML={{ __html: convertUrls(body) }}
          ></Container>
        </Box>
        {images.map((media) => (
          <Box>
            <Container maxWidth="5xl">
              {media.image && (
                <Box
                  as={Img}
                  width="100%"
                  fluid={media.image.localFile.childImageSharp.fluid}
                />
              )}
            </Container>
          </Box>
        ))}
      </Stack>
    </Layout>
  )
}
export default GenericPage

export const pageQuery = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      title
      slug
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_TextBlock {
            body
          }
          ... on WpPage_Pagebuilder_Layouts_Images {
            fieldGroupName
            images {
              image {
                id
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 60) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "husky.jpg" }) {
      colors {
        ...GatsbyImageColors
      }
      childImageSharp {
        fluid(maxWidth: 1440, quality: 60) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
